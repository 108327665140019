@include media-breakpoint-down(md){
     .header{
          float: left;
          width: 100%;
          position: fixed; top: 0; left: 0;
          z-index: 1000;
          padding: 20px 0px;
          transition-duration: .2s;
          
          &.header--active{
               opacity: 1;
               .header_content {
                    .header_content_logo{
                         // width: 100px;
                    }
               }
          }
          &.header--scroll{
               opacity: 0;
               pointer-events: none;
          }
          
          
          .header_languages{
               color: #afafaf;
               font-size: 12px;
               font-weight: 400;
               text-align: right;
               z-index: 100000;
               position: relative;
               margin-bottom: 0px;
               background-color: rgb(255, 255, 255);
               border-bottom: 1px solid #dadada;
               padding: 5px 0;
               a{
                    color: #e20010;
                    text-decoration: none;
                    &:hover{
                         
                    }
               }
          }
          
          .header_content{
               display: flex;
               justify-content: space-between;
               align-items: center;
               padding-left: 10px;
               .header_content_logo{
                    transition-duration: .2s;
                    .logo{
                         width: 150px;
                         img{
                              width: 100%;
                         }
                    }
               }
               .header_content_nav{
                    // display: none;
                    overflow: auto;
                    // display: block;
                    position: absolute;
                    left: 100%; top: 0;
                    height: 100vh;
                    width: 80%;
                    background-color: #f6f6f6;
                    padding-top: 130px;
     			align-content: flex-start;
     			align-items: flex-start;
                    transition-duration: .2s;
                    &.active{
                         left: 20%;
                    }
                    .nav_content{
                         width: 100%;
                         margin-bottom: 25px;
                         ul{
                              li{
                                   display: block;
                                   width: 100%;
                                   text-align: right;
                                   margin-left: 0;
                                   a{
                                        padding: 0px 30px;
                                        font-size: 20px;
                                        font-weight: bold;
                                        line-height: 2.5;
                                        letter-spacing: -0.5px;
                                        text-align: right;
                                        color: #000;
                                        &:hover{
                                             background-color: transparent;
                                             border-radius: 0px;
                                             // background-color: rgba(0,0,0, .3);
                                             box-shadow: none;
                                             border: none;
                                        }
                                        
                                        &::after{
                                             display: none;
                                        }
                                   }
                                   &.active{
                                        a{
                                             color: #e40010;
                                             text-decoration: underline;
                                        }
                                   }
                                   &.social{
                                        margin-left: 0px;
                                   }
                                   &:hover{
                                        ul{
                                             display: none;
                                        }
                                   }
                                   ul{
                                        // display: block;
                                        &.open{
                                             display: block;
                                        }
                                        width: 100%;
							margin-top: 10px;
                                        position: relative;
                                        padding: 15px;
                                        border-radius: 0px;
                                        &::before{
                                             left: auto;
                                             right: 45px;
                                        }
                                        li{
                                             margin-left: 0;
                                             a{
                                                  padding-right: 15px;
                                                  &:hover{
                                                       background-color: #fff;
                                                  }
                                             }
                                             &:hover ul, ul{
                                                  width: 100%;
                                                  position: relative;
                                                  padding: 0;
                                                  border-radius: 0px;
                                                  left: 0;
                                                  box-shadow: none;
                                                  
                                                  &::before, &::after{
                                                       display: none;
                                                  }
                                                  li{
                                                       a{
                                                            justify-content: flex-end;
                                                            &::before{
                                                                 right: -6px;
                                                                 left: auto;
                                                            }
                                                       }
                                                  }
                                             }
                                        }
                                        
                                   }
                              }
                         }
                    }
               
               }
               .header_content_hamburger{
                    display: flex;
     
                    
                    width: 34px; height: 32px;
                    flex-wrap: wrap;
                    align-content: space-between;
                    justify-content: center;
                    span{
                         height: 3px;
                         width: 100%; display: block;
                         background-color: #000;
                         border-radius: 100px;
                         position: relative;
                         transition-duration: .3s;
                     
                    }
                    &:hover{
                         span{
                              width: 100%;
                         }
                    }
                    &.active{
                         span{
                              width: 100%;
                              &:nth-child(1){
                                   transform: rotate(45deg);
                                   transform-origin: left;
                                   top: 2px;
                                   left: 4px;
                              }	
                              &:nth-child(2){ width: 0 }	
                              &:nth-child(3){
                                   transform: rotate(-45deg);
                                   transform-origin: left;
                                   top: -2px;
                                   left: 4px;
                              }	
                         }
                    }
               }
          }
     }
}