@include media-breakpoint-down(sm){
     .header{
          float: left;
          width: 100%;
          position: fixed; top: 0; left: 0;
          z-index: 1000;
          padding: 20px 0px;
          transition-duration: .2s;
          
          &.header--active{
               opacity: 1;
               .header_content {
                    .header_content_logo{
                         .logo{
                              width: 100px;
                       
                         }
                    }
               }
          }
          &.header--scroll{
               opacity: 0;
               pointer-events: none;
          }
          
          .header_content{
               .header_content_logo{
                    width: 230px;
                    transition-duration: .2s;
                    a{
                         width: 100%;
                         img{
                              width: 100%;
                         }
                    }
               }
               .header_content_nav{
                    width: 100%;
                    &.active{
                         left: 0%;
                    }
                    .nav_content{
                         width: 100%;
                         margin-bottom: 25px;
                         ul{
                              li{
                                   display: block;
                                   width: 100%;
                                   text-align: right;
                                   &.current-menu-item{
                                        a{
                                             color: #fff;
                                             // &::after{
                                             //      content: '';
                                             //      width: 200px; height: 2.5px;
                                             //      position: absolute;
                                             //      display: block;
                                             //      left: calc(100% - 6px);
                                             //      background-color: $white;
                                             //      transition-duration: .2s;
                                             //      top: 22px;
                                             // }
                                             
                                        }
                                   }
                                   a{
                                        padding: 0px 15px;
                                        font-size: 20px;
                                        font-weight: bold;
                                        line-height: 2.5;
                                        letter-spacing: -0.5px;
                                        text-align: right;
                                        &:hover{
                                             background-color: transparent;
                                             box-shadow: none;
                                             border-radius: 0px;
                                             &::after{
                                                  width: 0px;
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }
               .header_content_hamburger{
                    display: flex;
     
                    
                    width: 26px; height: 24px;
                    flex-wrap: wrap;
                    align-content: space-between;
                    justify-content: center;
                    span{
                         height: 2px;
                         width: 100%; display: block;
                         background-color: #000;
                         border-radius: 100px;
                         position: relative;
                         transition-duration: .3s;
                  
                    }
                    &:hover{
                         span{
                              width: 100%;
                         }
                    }
                    &.active{
                         span{
                              width: 100%;
                              &:nth-child(1){
                                   transform: rotate(45deg);
                                   transform-origin: left;
                                   top: 2px;
                                   left: 4px;
                              }	
                              &:nth-child(2){ width: 0 }	
                              &:nth-child(3){
                                   transform: rotate(-45deg);
                                   transform-origin: left;
                                   top: -2px;
                                   left: 4px;
                              }	
                         }
                    }
               }
          }
     }
}