.projection {
	float: left;
	width: 100%;
	button{
		display: inline;
		padding: 0;
		border: none;
		background-color: transparent;
	}
	
	.col-house:nth-child(1n + 4){
	}
	
	.projection_wrap {
		text-align: center;
		padding-bottom: 40px;
		@include media-breakpoint-down(md){
			padding-bottom: 20px;
		}	
		float: left;
		width: 100%;
		position: relative;
		
		&::before{
			content: '';
			position: absolute;
			top: 0; bottom: 0;
			right: 0;
			width: 100vw;
			border-radius: 0 40px 40px 0;
			background-color: #f6f6f6;
		}
		.projection_title {
			position: relative;
			z-index: 1;
			margin: 80px 0;
			@include media-breakpoint-down(sm){
				margin-top: 40px 0;
			}	
			span {
				color: #000000;
				font-size: 60px;
				font-weight: 300;
				line-height: 100%;
				margin-bottom: 50px;

				@include media-breakpoint-down(md) {
					font-size: 42px;
				}

				@include media-breakpoint-down(sm) {
					font-size: 32px;
					margin-bottom: 30px;
				}

				&::after {
					content: '.';
					margin-left: 15px;
					color: #c4261e;
					display: inline;
				}
			}

			button {
				position: absolute;
				right: 70px;
				text-decoration: underline;
				top: 28px;
				
				@include media-breakpoint-down(md){
top: 20px;
				}	
				@include media-breakpoint-down(sm){
position: relative;
right: 0;
display: block;
margin: 0 auto;
top: 0;
margin-top: 20px;
				}	
			}
		}

		.house {
			text-align: center;
			margin-bottom: 90px;

			@include media-breakpoint-down(sm){
margin-bottom: 40px;
			}	
			.house_projection {
				margin-bottom: 50px;

				img {
					max-width: 100%;
				}
			}

			.title {
				color: #2b2b2b;
				font-size: 18px;
				font-weight: 300;
				line-height: 18px;
				font-weight: 300;

			}

			.subtitle {
				color: #c4261e;
				font-size: 12px;
				font-weight: 500;
			}
		}
	}
	
	
	.house_popup{
		position: fixed;
		top: 0;
		background-color: rgba(167, 167, 167, .65);
		left: 0;
		
	
		
		
		z-index: 10;
		
		display: none;
		
		.house_popup_wrap{
			height: 100vh;
			width: 100vw;
			display: flex;
			align-items: center;
			justify-content: center;
		max-height: 100vh;
			
		}
		.popup{
			max-width: 800px;
			width: 100%;
			padding: 60px 80px; 
			background-color: #fff;
			max-height: 100vh;
			overflow: auto;
			border-radius: 12px;
			position: relative;
			
			@include media-breakpoint-down(sm){
				height: 100vh;
				width: 100vw;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
			max-height: 100vh;
			}	
			.close{
				cursor: pointer;
				position: absolute;
				right: 10px;
				top: 10px;
				color: #2b2b2b;
				font-size: 24px;
				font-weight: 300;
				line-height: 18px;
			}
			.title{
				color: #2b2b2b;
				font-size: 24px;
				font-weight: 300;
				line-height: 18px;
				margin-bottom: 40px;

			}
			.image{
				width: 100%;
				img{
				width: 100%;
				
				}
			}
			.size{
				color: #2b2b2b;
				margin-top: 80px;
				font-size: 18px;
				font-weight: 300;
				line-height: 18px;
			}
		}
	}

}