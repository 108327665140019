.intro {
	float: left;
	width: 100%;
	.container{
		position: relative;
		.intro_background{
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100vw;
			border-radius: 0 0 0 40px;
			background-size: cover;
		}
	}
	.slick-list{
		overflow: visible;
	}
	
	.slick-slide{
		position: relative;
		opacity: 0;
		&.slick-active{
			opacity: 1;
		}
	}
	.intro_wrap {
		height: 974px;
		max-height: 100vh;
		min-height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		padding: 150px 0;
		width: 100%;
		text-shadow: 0 0 31px rgba(19, 26, 69, 0.7);
		color: #ffffff;
		font-family: $font2;
		text-align: center;
		max-width: 1087px;
		margin: 0 auto;
		
		@include media-breakpoint-down(md){
			height: 700px;
		}	
		@include media-breakpoint-down(sm){
			height: 500px;
		}	

		h1 {
			font-size: 120px;
			font-weight: 700;
			line-height: 100%;
			text-transform: uppercase;
			
			@include media-breakpoint-down(lg){
				font-size: 100px;
			}	
			@include media-breakpoint-down(md){
				font-size: 80px;
			}	
			@include media-breakpoint-down(sm){
				font-size: 42px;
			}	
		}

		p {
			font-size: 30px;
			font-weight: 300;
			margin-top: 30px;
			@include media-breakpoint-down(md){
				font-size: 24px;
			}	
			@include media-breakpoint-down(sm){
				font-size: 18px;
			}	
		}

		.intro_button {
			position: absolute;
			bottom: 50px;

			a {}
		}
	}
}