.gallery {
	float: left;
	width: 100%;

	.gallery_title {
		position: relative;
		z-index: 1;
		margin: 80px 0;
		text-align: center;
		@include media-breakpoint-down(sm) {
			margin-top: 40px 0;
		}

		span {
			color: #000000;
			font-size: 60px;
			font-weight: 300;
			line-height: 100%;
			margin-bottom: 50px;

			@include media-breakpoint-down(md) {
				font-size: 42px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 32px;
				margin-bottom: 30px;
			}

			&::after {
				content: '.';
				margin-left: 15px;
				color: #c4261e;
				display: inline;
			}
		}
	}

	.cell {
		margin-bottom: 30px;

		.gallery_item {
			display: flex;
			width: 100%;
			background-size: cover;
			border-radius: 40px;
			height: 18vw;
			align-items: center;
			position: relative;
			overflow: hidden;
			justify-content: center;
		background-position: center;
			@include media-breakpoint-down(md){
				height: 22vw;

			}	
			@include media-breakpoint-down(sm){
				height: 24vw;
				min-height: 250px;

			}	
			span {
				color: transparent;
				text-decoration: none;
				position: relative;
				z-index: 1;
				text-decoration: none;
				
				transition-duration: .2s;	
			}
			&:hover{
				text-decoration: none;
				
				span{
					color: #fff;
					text-decoration: none;
				}
				&::after{
					opacity: 1;
				}
			}
			&::after{
				background-color: rgba(0, 0, 0, 0.486);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				transition-duration: .2s;	
				opacity: 0;
				content: '';
				
			}
		}
	}
}