.header {
	float: left;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	padding: 10px 0;
	transition-duration: .2s;
	@include media-breakpoint-down(md){
		padding: 10px;
	}	
	&.header--active {
		opacity: 1;
		padding: 0;
		background: #fff;

		.header_content {
			.header_content_logo {
				.logo {
					width: 140px;
				}
			}
		}
		
		.header_content .header_content_nav .nav_content ul li a{
			color: #000;
		}
	}

	&.header--scroll {
		opacity: 0;
		pointer-events: none;
	}

	.header_content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 50px;
		
		@media (max-width: 1520px){
		padding: 0 30px;
			
		}
		@media (max-width: 1300px){
		padding: 0 20px;
			
		}
		
		@include media-breakpoint-down(md){
			padding: 0;
		}
		
		@include media-breakpoint-down(sm){
			
		}	

		.header_content_logo {
			width: auto;
			transition-duration: .2s;
			display: flex;
			align-items: center;
			position: relative;
			z-index: 1000;

			.logo {
				float: left;
				width: 313px;
				transition-duration: .2s;
				@media (max-width: 1520px){
					width: 220px;
				}
				@media (max-width: 1300px){
					width: 180px;
				}
				img {
					width: 100%;
					transition-duration: .2s;
				}
			}
		}

		.header_content_nav {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.nav_content {
				ul {
					margin: 0;

					li {
						display: inline-block;
						position: relative;

						a {
							position: relative;
							display: block;
							padding: 15px 0;
							font-weight: 400;
							line-height: auto;
							transition-duration: .2s;
							color: #ffffff;
							font-family: $font1;
							font-size: 16px;
							text-transform: uppercase;
							text-decoration: none;
							
							@media (max-width: 1080px){
								font-size: 14px;
							}	

							&:hover {
								color: #e72d2e;
							}

							&::after {
								content: '';
								margin: 0 35px;
								display: inline-block;
								height: 17px;
								top: 3px;
								position: relative;
								width: 1px;
								background-color: #fff;
								@media (max-width: 1520px){
									margin: 0 20px;
								}	
								@media (max-width: 1300px){
									margin: 0 10px;
								}	
							}
						}

						&:nth-last-child(1),
						&:nth-last-child(2),
							{
							a {
								&::after {
									display: none;
								}
							}
						}


						&.active {
							a {
								color: #e72d2e;

								text-decoration: underline;
							}
						}

						&.social {
							margin-left: 3.25vw;
							img{
							width: 34px;
							}
						}

						&:hover {
							ul {
								display: block;
							}
						}

						ul {

							display: none;
							position: absolute;
							background-color: $red;
							top: 100%;
							width: auto;
							padding: 20px;
							border-radius: 00px;
							box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

							&::before {
								content: '';
								border: 10px solid transparent;
								border-bottom-color: $red;
								position: absolute;
								top: -20px;
								left: 30px;
							}

							li {
								display: block;
								border: none;
								position: initial;
								text-align: left;
								margin-left: 0;
								width: 100%;
								margin-bottom: 10px;

								&:last-child {
									margin-bottom: 0;
								}

								a {
									font-size: 16px;
									font-weight: 800;
									color: #fff;
									white-space: nowrap;
									text-shadow: none;
									padding: 0;
									text-align: left;

									border: none;
									padding-right: 0px;
									box-shadow: none;
									transition-duration: .2s;

									position: relative;
									left: 0;

									&:hover {
										box-shadow: none;
										border: none;
										color: #fff;
										left: 5px;
										text-decoration: none;
									}
								}

								&.current-menu-item {
									a {
										box-shadow: none;
										border: none;
										color: #fff;
										text-decoration: none;
									}

									ul {
										li {
											a {
												color: #000;
											}
										}
									}

								}
							}
						}
					}
				}
			}
		}

		.header_content_hamburger {
			display: none;

			width: 34px;
			height: 32px;
			flex-wrap: wrap;
			align-content: space-between;
			justify-content: center;

			span {
				height: 3px;
				width: 100%;
				display: block;
				background-color: #000;
				border-radius: 100px;
				position: relative;
				transition-duration: .3s;
			}

			&:hover {
				span {
					width: 100%;
				}
			}

			&.active {
				span {
					width: 100%;

					&:nth-child(1) {
						transform: rotate(45deg);
						transform-origin: left;
						top: 2px;
						left: 4px;
					}

					&:nth-child(2) {
						width: 0
					}

					&:nth-child(3) {
						transform: rotate(-45deg);
						transform-origin: left;
						top: -2px;
						left: 4px;
					}
				}
			}
		}
	}
}



@import 'rwd/tablet';
@import 'rwd/mobile';