.about_us {
	float: left;
	width: 100%;
	text-align: center;
	padding: 70px 0 100px 0;
	@include media-breakpoint-down(sm){
		padding: 60px 0;
	}	
	.wrap {
		max-width: 1115px;
		margin: 0 auto;

		h2 {
			color: #000000;
			font-size: 60px;
			font-weight: 300;
			line-height: 100%;
			margin-bottom: 50px;
			@include media-breakpoint-down(md){
				font-size: 42px;
			}	
			@include media-breakpoint-down(sm){
				font-size: 32px;
				margin-bottom: 30px;
			}	

			&::after {
				content: '.';
				margin-left: 15px;
				color: #c4261e;
				display: inline;
			}
		}

		p {
			margin-bottom: 30px;
			color: #2b2b2b;
			font-size: 18px;
			font-weight: 300;
			line-height: 30px;
			@include media-breakpoint-down(sm){
				font-size: 16px;
				margin-bottom: 20px;
			}	
		}
	}
}