.button{
	background-color: $red;
	width: 270px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	font-family: $font2;
	transition-duration: .2s;	
	&:hover{
		text-decoration: none;
		color: #fff;
		background-color: darken($red, 10%);
	}
}