.footer {
	float: left;
	width: 100%;
	.container {
		position: relative;

		.footer_map {
			position: relative;
			width: 100vw;
			height: 520px;
			z-index: 1;
			overflow: hidden;
			border-radius: 40px 0 0px 40px;

			#map {
				width: 100%;
				height: 100%;
			}
		}


	}

	.footer_content {
		margin-top: -360px;
		float: left;
		width: 100%;
		
		position: relative;
		.wrap {
			position: relative;
			padding-top: 360px;
			padding-bottom: 30px;
			&::before {
				width: 100vw;
		border-radius: 0 40px 40px 0;
				height: 100%;
				content: '';
		background-color: #f6f6f6;
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		.footer_content_title {
			position: relative;
			z-index: 1;
			margin: 80px 0;
			text-align: center;

			@include media-breakpoint-down(sm) {
				margin-top: 40px 0;
			}

			span {
				color: #000000;
				font-size: 60px;
				font-weight: 300;
				line-height: 100%;
				margin-bottom: 50px;

				@include media-breakpoint-down(md) {
					font-size: 42px;
				}

				@include media-breakpoint-down(sm) {
					font-size: 32px;
					margin-bottom: 30px;
				}

				&::after {
					content: '.';
					margin-left: 15px;
					color: #c4261e;
					display: inline;
				}
			}
		}

		.footer_wrap {
			p {
				color: #000000;
				font-family: $font2;
				font-size: 18px;
				font-weight: 700;
				line-height: 30px;

				strong {
					color: #c4261e;
				}

				a {
					font-weight: 400;
					color: #000;

					strong {
						color: #000;
					}

					&.red {
						color: #c4261e;
					}
				}

				&.small {
					font-size: 12px;
					font-weight: 400;
					max-width: 356px;
					line-height: 1.5em;
				}

			}
		}
		.cell{
			@include media-breakpoint-down(sm){
				margin-bottom: 40px;
			}	
		}
		form {
			@include media-breakpoint-down(md){
				padding-top: 60px;
			}	
			@include media-breakpoint-down(sm){
				padding-top: 0;
			}	
			.row {
				margin: 0 -3px;
			}

			.cell {
				margin-bottom: 6px;
				padding: 0 3px;
			
			}

			input,
			textarea {
				width: 100%;
				background-color: #ffffff;
				border: none;
				padding: 0 15px;
				height: 158px;
				color: #000000;
				font-family: $font2;
				font-size: 18px;
				font-weight: 400;
				line-height: 30px;
				resize: none;
			}

			input {
				height: 37px;
			}

			textarea {
				padding-top: 10px;
			}

			.button {
				margin-top: 10px;
				width: 194px;
				height: 36px;
				background-color: #c4261e;
				color: #ffffff;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}

	.footer_bottom {
		color: #5e5e5e;
		font-size: 11px;
		font-weight: 300;
		float: left;
		width: 100%;
		padding: 40px 0;

		.container {
			display: flex;
			justify-content: space-between;
		}

		.left {}

		.right {
			a {
				color: #da251d;
			}
		}
	}
}