

@font-face {
    font-family: gotham;
    src: url(../fonts/Gotham-Book.otf);
    font-weight: 400;
  }
@font-face {
    font-family: gotham;
    src: url(../fonts/Gotham-Medium.otf);
    font-weight: 500;
}
$font1: gotham, sans-serif;
$font2: 'Lato', sans-serif;

@import 'buttons';
@import 'colors';

html, body{
    max-width: 100%;
    overflow-x: hidden;
    max-width: 100%;
    width: 100%;
    min-width: 320px;
    font-family: $font1;
}

.container{
    max-width: 1580px;
    width: 100%;
    &--small{
        max-width: 1440px;
    }
}